.dice {
    width: 100px;
    height: 100px;
    text-align: center;
    border: 2px solid var(--dark-accent);
    background-color: var(--sub-accent);
    border-radius: 10px;
    color: var(--dark-accent);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 
        0px 0px 0px 1px rgba(0,0,0,.06),
        0px 1px 1px -0.5px rgba(0,0,0,.06),
        0px 3px 3px -1.5px rgba(0,0,0,.06),
        0px 6px 6px -3px rgba(0,0,0,.06),
        0px 12px 12px -6px rgba(0,0,0,.06),
        0px 24px 24px -12px rgba(0,0,0,.06);
    transition: all .25s ease-in-out;
    p {
        padding:0;
        margin:0;
        font-size: 42px;
        font-weight: 700;
    }
}
// .dice:hover {
//     border: 2px solid red;
// }

.diceLocked {
    width: 100px;
    height: 100px;
    text-align: center;
    border: 4px solid var(--accent-color);
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
        font-size: 42px;
        font-weight: 700;
    }
}

@media only screen and (max-width: 768px) {
    // .dice {
    //     width: 75px;
    //     height: 75px;
    // }
    // .diceLocked {
    //     width: 75px;
    //     height: 75px;
    // }
}