.homeCont {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: auto;

    .homeHead {
        text-align: center;
    }
    h1 {
        margin: 0px 0;
        font-size: 64px;
        font-family: "Bungee Shade", sans-serif;
        font-weight: 400;
        font-style: normal;
        max-height: 140px;
    }
    ul {
        list-style-type: none;
        text-align: center;
        font-size: 20px;
    }
    button {
        width: 100%;
        font-size: 28px;
        padding: 10px 0;
        margin-top: 1rem;
        box-shadow: 
            0px 0px 0px 1px rgba(0,0,0,.06),
            0px 1px 1px -0.5px rgba(0,0,0,.06),
            0px 3px 3px -1.5px rgba(0,0,0,.06),
            0px 6px 6px -3px rgba(0,0,0,.06),
            0px 12px 12px -6px rgba(0,0,0,.06),
            0px 24px 24px -12px rgba(0,0,0,.06);
    }
    img {
        width: 100px;
    }
}

.homeDiceCont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 1rem;
    width: 100%;
}

.homeDiceBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    background-color: var(--sub-accent);
    border: 2px solid var(--sub-accent);
    color: var(--dark-accent);
    border-radius: 10px;
    transition: all .1s ease-in-out;

    h2 {
        font-size: 32px;
    }
}

.activeDiceBox {
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 24px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 24px -8px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 8px 0px rgba(255, 225, 197,0.75);
    
}

@media only screen and (max-width: 768px) {
    .homeCont {
        padding: 20px;
        gap: 10px;
        margin-top: 3rem;
        max-width: 340px;
        ul {
            font-size: 18px;
        }
        button {
            font-size: 20px;
        }
    }
    .homeHead {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            display: inline-block;
            text-align: center;
            margin: auto;
            font-size: 32px;
        }
    }
}