$primary-color: rgb(255, 237, 222);
$background-color: rgb(17, 17, 17);

.navCont {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    border-right: 1px solid black;
    height: 100vh;
    padding: 0 20px;
    gap: 20px;
    min-width: 200px;
    justify-content: space-between;
    z-index: 100;
    background-color: var(--dark-accent);
}

.navBtns {
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    h1 {
        margin: 20px 0;
        position: relative;
        z-index: 100;
    }
    button {
        font-size: 20px;
        padding: 10px;
        box-shadow: 
            0px 0px 0px 1px rgba(0,0,0,.06),
            0px 1px 1px -0.5px rgba(0,0,0,.06),
            0px 3px 3px -1.5px rgba(0,0,0,.06),
            0px 6px 6px -3px rgba(0,0,0,.06),
            0px 12px 12px -6px rgba(0,0,0,.06),
            0px 24px 24px -12px rgba(0,0,0,.06);
    }
    .hamburger {
        display: none;
        font-size: 2rem;
        cursor: pointer;
        margin-top: 1rem;
        position: absolute;
        z-index: 100;
        right: 18px;
    }
    .sidebar {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        left: -100%;
        width: 100vw;
        height: 100%;
        background-color: white;
        transition: left 0.3s ease;
    }
    .sidebar.open {
        left: 0;
    }
}

.navImg {
    position: relative;
    z-index: 100;
    max-width: 40px;
    margin: 1.25rem auto 0 auto;
}
img {
    width: 100%;
}

.navFooter {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    padding-bottom: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;

    button {
        font-size: 16px;
        padding: 3px 5px; 
    }
}

@media only screen and (max-width: 768px) {
    .navImg {
        margin: 1.25rem 0 0 0;
    }
    .navCont {
        flex-direction: column;
        height: auto;
        justify-content: space-between;
        border-right: none;
    }
    .navBtns {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    
        h1 {
            margin: 20px 0;
        }
        button {
            height: auto;
            width: auto;
        }
        .hamburger {
            display: block;
        }
        .sidebar {
            position: fixed;
            top: 0;
            left: -100%;
            height: 100%;
            background-color: var(--dark-accent);
            color: var(--accent-color);
            padding: 1rem;
            padding-top: 5rem;
            display: flex;
            gap: 15px;
            transition: left 0.2s ease;
        }
        .sidebar button {
            border: none;
            text-align: center;
            padding: 1rem;
            width: 100%;
        }
    }
    .navFooter {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1rem;

        button {
            width: fit-content !important;
            font-size: 16px;
            padding: 5px !important;
            color: black;
        }
    }
}