.gameCont {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: auto;
    text-align: center;
    h1 {
        margin: 0px 0;
        color: var(--accent-color);
    }
    h2 {
        margin: 0px 0;
    }
}

.playerListCont {
    width: 100%;
}

.gameCodeCont {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0 0px 0;
    button {
        padding: 5px 5px 3px 5px;
    }
    img {
        width: 20px;
    }
    p {
        display: inline-block;
        margin-left: 10px;
        font-family: Arial, Helvetica, sans-serif;
    }
}

.playerList {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;

    span {
        border: 1px solid white;
        width: 30px;
        height: 30px;
        padding-top: 5px;
        border-radius: 100%;
    }
}

.currentPlayer {
    border: 1px solid var(--accent-color) !important;
    color: var(--accent-color);
}

.playerBoard {
    display: flex;
    row-gap: 20px;
    flex-wrap: wrap;
    max-width: 386px;
    justify-content: space-between;
}

.topScore {
    display: flex;
    align-items: center;
    gap: 10px;

    h2,h1 {
        display: inline-block;
    }
}

.scorecard {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--sub-accent);
}

.dice {
    width: 100px;
    height: 100px;
    text-align: center;
    border: 2px solid gray;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .25s ease-in-out;

    p {
        padding:0;
        margin:0;
        font-size: 42px;
    }
}

.diceLocked {
    width: 100px;
    height: 100px;
    text-align: center;
    border: 2px solid red;
    box-sizing: border-box;
    font-size: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.playBtns {
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 386px;

    button {
        width: 100%;
        min-width: 147px;
        font-size: 32px;
        padding: 20px;
    }
}

.statsCont {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: var(--sub-accent);
}

.tieAnd {
    margin-left: 10px !important;
}

.helpBtn {
    position: absolute;
    top: 0;
    right: 40px;
    z-index: 10;
    font-size: 24px;
    padding: 5px 10px;
    border-radius: 5px;
    width: 30px;
    height: 31px;
    padding: 0px !important;
}
.helpModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--sub-accent);
    z-index: 10;
    padding: 32px;
    border-radius: 10px;
    color: var(--dark-accent);
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    box-shadow: 
        0px 0px 0px 1px rgba(0,0,0,.06),
        0px 1px 1px -0.5px rgba(0,0,0,.06),
        0px 3px 3px -1.5px rgba(0,0,0,.06),
        0px 6px 6px -3px rgba(0,0,0,.06),
        0px 12px 12px -6px rgba(0,0,0,.06),
        0px 24px 24px -12px rgba(0,0,0,.06);
    button {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 32px;
        background-color: transparent;
        border: none;
        color: var(--dark-accent);
    }
    h2 {
        font-size: 28px;
        margin-top: 30px;
    }
}
.helpModal ul {
    list-style: none; /* Remove default bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}
.helpModal ul li {
    position: relative;
    padding-left: 1.5em; /* Space for custom bullet */
    margin-bottom: 0.5em; /* Space between items */
    text-align: left;
}

.helpModal ul li::before {
    content: "•"; /* Custom bullet */
    position: absolute;
    left: 0;
    top: -2px;
    color: #000; /* Bullet color */
    font-size: 1.5em; /* Adjust bullet size */
    line-height: 1; /* Align bullet vertically */
}

@media only screen and (max-width: 768px) {
    .gameCont {
        justify-content: flex-start;
        margin: 0;
        padding: 0 20px;
    }
    .dice {
        width: 90px;
        height: 90px;
    }
    .diceLocked {
        width: 90px;
        height: 90px;
    }
    .helpModal {
        width: calc(100% - 60px);
        max-width: 430px;
        top: 45%;
    }
    .helpModal ul {
        list-style: none; /* Remove default bullets */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margin */
    }
    
    .helpModal ul li {
        position: relative;
        padding-left: 1.5em; /* Space for custom bullet */
        margin-bottom: 0.5em; /* Space between items */
        text-align: left;
    }
    
    .helpModal ul li::before {
        content: "•"; /* Custom bullet */
        position: absolute;
        left: 0;
        top: -2px;
        color: #000; /* Bullet color */
        font-size: 1.5em; /* Adjust bullet size */
        line-height: 1; /* Align bullet vertically */
    }
}