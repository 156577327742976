:root {
    --bg-color: #FF5F00;
    --accent-color: rgb(255, 159, 102);
    --sub-accent: rgb(255, 225, 197);
    --dark-accent: rgb(55, 58, 64);
    --pop-color: rgb(255, 117, 11);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

button {
    cursor: pointer;
    background-color: var(--accent-color);
    border: none;
    border-radius: 5px;
    color: var(--dark-accent);
    font-weight: 700;

    &:disabled {
        background-color: rgba(255, 159, 102, .5);
    }
}

.App {
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--dark-accent);
    color: var(--sub-accent);
}

@media only screen and (max-width: 768px) {
    .App {
        flex-direction: column;
        
    }
}