
.board {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex: 1;
}

.gameCont {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: auto;
    height: 100%;
    h1 {
        margin: 20px 0;
    }
}

.playerBoard {
    display: flex;
    row-gap: 20px;
    flex-wrap: wrap;
    max-width: 386px;
    justify-content: space-between;
}

.playBtns {
    display: flex;
    gap: 20px;
    width: 100%;

    button {
        width: 100%;
        min-width: 147px;
        font-size: 32px;
        padding: 20px;
    }
}

.finishBtns {
    display: flex;
    width: 100%;
    gap: 20px;
}

.statsCont {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: var(--sub-accent);


}

.scoreBtn {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 10;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 
        0px 0px 0px 1px rgba(0,0,0,.06),
        0px 1px 1px -0.5px rgba(0,0,0,.06),
        0px 3px 3px -1.5px rgba(0,0,0,.06),
        0px 6px 6px -3px rgba(0,0,0,.06),
        0px 12px 12px -6px rgba(0,0,0,.06),
        0px 24px 24px -12px rgba(0,0,0,.06);
}

.helpBtn {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 10;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 
        0px 0px 0px 1px rgba(0,0,0,.06),
        0px 1px 1px -0.5px rgba(0,0,0,.06),
        0px 3px 3px -1.5px rgba(0,0,0,.06),
        0px 6px 6px -3px rgba(0,0,0,.06),
        0px 12px 12px -6px rgba(0,0,0,.06),
        0px 24px 24px -12px rgba(0,0,0,.06);
}

.scoreList {
    position: absolute;
    top: 55px;
    left: 0px;
    z-index: 9;
    height: calc(100% - 65px);
    padding: 0 10px 0 10px;
    overflow-y: scroll;
    text-align: left;
    min-width: 125px;
    border-radius: 5px;
    
    // scrollbar-gutter: stable both-edges;
    h3 {
        margin: 10px 0;
    }
}

.individualScore {
    background-color: var(--sub-accent);
    color: var(--dark-accent);
    font-weight: 500;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: 
        0px 0px 0px 1px rgba(0,0,0,.06),
        0px 1px 1px -0.5px rgba(0,0,0,.06),
        0px 3px 3px -1.5px rgba(0,0,0,.06),
        0px 6px 6px -3px rgba(0,0,0,.06),
        0px 12px 12px -6px rgba(0,0,0,.06),
        0px 24px 24px -12px rgba(0,0,0,.06);
}

.resetScores {
    background-color: var(--sub-accent);
    color: var(--dark-accent);
    padding: 3px 5px;
    margin-bottom: .5rem;
    width: 100%;
    font-weight: 100;
}

.helpModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--sub-accent);
    z-index: 10;
    padding: 32px;
    border-radius: 10px;
    color: var(--dark-accent);
    display: flex;
    flex-direction: column;
    gap: 10px;

    box-shadow: 
        0px 0px 0px 1px rgba(0,0,0,.06),
        0px 1px 1px -0.5px rgba(0,0,0,.06),
        0px 3px 3px -1.5px rgba(0,0,0,.06),
        0px 6px 6px -3px rgba(0,0,0,.06),
        0px 12px 12px -6px rgba(0,0,0,.06),
        0px 24px 24px -12px rgba(0,0,0,.06);
    button {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 32px;
        background-color: transparent;
        border: none;
        color: var(--dark-accent);
    }
    ul {
        list-style-position: inside;
        margin: 10px 0;
        li {
            margin-bottom: 5px;
        }
    }
    h2 {
        font-size: 28px;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .gameCont {
        padding: 36px 20px 0 20px;
        margin: 0 auto;
        height: fit-content;
        justify-content: flex-start;
        h1 {
            font-size: 28px;
        }
    }
    .playBtns {

        button {
            font-size: 28px;
            padding: 10px;
        }
    }
    .helpModal {
        width: calc(100% - 60px);
        max-width: 430px;
        top: 40%;
        text-align: center;
    }
    .helpModal ul {
        list-style: none; /* Remove default bullets */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margin */
    }
    
    .helpModal ul li {
        position: relative;
        padding-left: 1.5em; /* Space for custom bullet */
        margin-bottom: 0.5em; /* Space between items */
        text-align: left;
    }
    
    .helpModal ul li::before {
        content: "•"; /* Custom bullet */
        position: absolute;
        left: 0;
        top: -2px;
        color: #000; /* Bullet color */
        font-size: 1.5em; /* Adjust bullet size */
        line-height: 1; /* Align bullet vertically */
    }
    .scoreList {
        background-color: var(--accent-color);
        text-align: left;
        min-width: 125px;
        height: fit-content;
        max-height: 461px;
        left: 20px;
        box-shadow: 
            0px 0px 0px 1px rgba(0,0,0,.06),
            0px 1px 1px -0.5px rgba(0,0,0,.06),
            0px 3px 3px -1.5px rgba(0,0,0,.06),
            0px 6px 6px -3px rgba(0,0,0,.06),
            0px 12px 12px -6px rgba(0,0,0,.06),
            0px 24px 24px -12px rgba(0,0,0,.06);
        h3 {
            color: var(--dark-accent);
        }
    }
    .individualScore{
        background-color: var(--dark-accent);
        color: var(--sub-accent);
    }
    .resetScores {
        background-color: var(--dark-accent);
        color: var(--sub-accent);
        padding: 3px 5px;
        margin-bottom: .5rem;
        width: 100%;
        font-weight: 100;
    }
}