.chatContainer {
    position: absolute;
    top: 61px;
    right: 0;
    display: flex;
    flex-direction: column;
    height: 75%;
    background: var(--sub-accent);
    padding: 10px;
    color: var(--dark-accent);
    overflow-y: scroll;
    border-radius: 5px 0 0 5px;
    box-shadow: 
            0px 0px 0px 1px rgba(0,0,0,.06),
            0px 1px 1px -0.5px rgba(0,0,0,.06),
            0px 3px 3px -1.5px rgba(0,0,0,.06),
            0px 6px 6px -3px rgba(0,0,0,.06),
            0px 12px 12px -6px rgba(0,0,0,.06),
            0px 24px 24px -12px rgba(0,0,0,.06);
}

.chatClosed {
    display: none;
}

.chatMessages {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 10px;
}

.chatMessage {
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 4px;
    text-align: left;
    max-width: 200px;
}

.chatUser {
    font-weight: bold;
}

.chatForm {
    display: flex;
    gap: 5px
}

.chatInput {
    flex-grow: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.chatSendBtn {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
