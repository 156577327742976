.statsCont {
    padding: 0 20px;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100%;
}

.statsHead {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    img {
        width: 100%;
        max-width: 100px;
        height: 100px;
    }
    h1 {
        font-size: 52px;
    }
}

.statsInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h1 {
        text-align: center;
    }
}

.statList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.statItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1 1 calc(33.33% - 20px);
}

@media only screen and (max-width: 768px) {
    .statsCont {
        padding: 0 20px;
        justify-content: flex-start;
        margin: 2rem 0 0 0;
        height: 100%;
    }
}